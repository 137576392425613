import React from 'react'

import styles from './BusinessPage.module.scss'
import {
  FormInput,
  FormRow,
  FormCol,
  FormContainer,
} from 'components/shared/Forms'
import Button from 'components/shared/Button'

export default () => (
  <FormContainer width="600px" className={styles.businessForm}>
    <h2>Partner with us</h2>
    <form
      className={styles.formInner}
      role="business-form"
      id="business-form"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="bot-field" />
      <FormRow>
        <FormCol>
          <FormInput label="First Name">
            <input
              type="fname"
              name="fname"
              autoFocus
              required
              id="fname"
              maxLength="250"
            />
          </FormInput>
        </FormCol>
        <FormCol>
          <FormInput label="Last Name">
            <input type="lname" name="lname" id="lname" maxLength="250" />
          </FormInput>
        </FormCol>
      </FormRow>

      <FormRow>
        <FormCol>
          <FormInput label="Company Name">
            <input
              type="cname"
              name="cname"
              required
              id="cname"
              maxLength="250"
            />
          </FormInput>
        </FormCol>
        <FormCol>
          <FormInput label="Website">
            <input type="website" name="website" id="website" maxLength="250" />
          </FormInput>
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormInput label="Email">
            <input type="email" name="email" id="email" />
          </FormInput>
        </FormCol>
        <FormCol>
          <FormInput label="Phone number">
            <input type="pnumber" name="pnumber" id="pnumber" maxLength="250" />
          </FormInput>
        </FormCol>
      </FormRow>
      <Button big full>
        Register
      </Button>
    </form>
  </FormContainer>
)
