import React from 'react'

import Layout from 'layouts/Page'
import SEO from 'components/shared/SEO'
import BusinessPage from 'components/BusinessPage'

export default ({ location }) => (
  <Layout navbar="main" location={location}>
    <SEO title="Register a business" />
    <BusinessPage />
  </Layout>
)
