import React from 'react'

import styles from './BusinessHeader.module.scss'
import AdventureImg from '../../../static/img/for-business/adventure.jpeg'
import CanoeImg from '../../../static/img/for-business/canoe.jpeg'
import EventImg from '../../../static/img/for-business/event-room.jpeg'
import LakeImg from '../../../static/img/for-business/lake.jpeg'
import WeddingImg from '../../../static/img/for-business/wedding.jpeg'
import WineImg from '../../../static/img/for-business/wine.jpeg'
import {
  DesktopIcon,
  DollyIcon,
  PeopleIcon,
  HandsIcon,
} from 'components/shared/Icons'

export default () => (
  <>
    <div className={styles.leftContainer}>
      <img className={styles.stuckImg} src={AdventureImg} />
      <img className={styles.stuckImg} src={CanoeImg} />
      <img className={styles.stuckImg} src={EventImg} />
    </div>
    <div className={styles.rightContainer}>
      <img className={styles.stuckImg} src={LakeImg} />
      <img className={styles.stuckImg} src={WeddingImg} />
      <img className={styles.stuckImg} src={WineImg} />
    </div>
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.logo} />
        <div className={styles.title}>Preregister as a Rental Company</div>
        <div className={styles.subTitle}>
          Be the first to reach our members.
        </div>
      </div>
      <div className={styles.subHeaderBox}>
        <div className={styles.grid}>
          <div className={styles.gridItem}>
            <div className={styles.row}>
              <DesktopIcon height={15} />
              <p>Amplify your online precense</p>
            </div>
          </div>
          <div className={styles.gridItem}>
            <div className={styles.row}>
              <DollyIcon height={18} />
              <p>Advanced tools for managing your inventory</p>
            </div>
          </div>
          <div className={styles.gridItem}>
            <div className={styles.row}>
              <PeopleIcon height={15} />
              <p>Extend your customer reach</p>
            </div>
          </div>
          <div className={styles.gridItem}>
            <div className={styles.row}>
              <HandsIcon height={15} />
              <p>Connect with a growing network of partners</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)
