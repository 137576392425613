import React from 'react'

import styles from './BusinessPage.module.scss'
import BusinessForm from './BusinessForm'
import BusinessHeader from './BusinessHeader'

export default () => (
  <div className={styles.container}>
    <BusinessHeader />
    <BusinessForm />
  </div>
)
